import React from 'react'
import Typed from 'typed.js';
import { useSelector } from 'react-redux';

function IntroRu() {
  const el = React.useRef(null);
  const { portfolioData } = useSelector((state) => state.root);
  const { introrus } = portfolioData;
  const { firstName, lastName, welcomeText, description, caption } = introrus;


  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);


  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: caption,
      typeSpeed: 60,
      backSpeed: 15,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
      <div className='h-[45vh] lg:h-[80vh] sm:h-[90vh] si:h-[145vh] bg-primary flex flex-col items-start justify-center gap-8'>
        <h1 className='text-white text-2xl font-serif sm:text-sm sm:mt-20 rs:mt-[250px]'>{welcomeText || ''}</h1>
        <h1 className='text-7xl sm:text-4xl si:text-3xl text-secondary font-semibold font-serif'>{firstName || ''} {lastName || ''}</h1>
        <div className='text-5xl lg:text-4xl sm:text-3xl text-white font-bold'>
          <span ref={el || null} />
        </div>
        <p className='text-xl lg:text-base text-white font-serif w-7/8 sm:w-4/5'>{description || ''}</p>
        <a href="/admin-login" className='border-2 border-tertiary text-tertiary text-xl lg:text-base font-semibold px-12 py-3 rounded sm:mb-10'>
          Продолжить
        </a>
      </div>
    </div>
  )
}

export default IntroRu