import React from 'react'

function Footer() {
    return (
        <div className='py-10 sm:py-0 sm:mt-10'>
            <div className='h-[1px] w-full bg-gray-500'>
            </div>

            <div className='flex flex-col items-center justify-center mt-10 opacity-75'>
                <h1 className='text-white font-serif text-xl lg:text-lg'>
                    Разработано и Создано
                </h1>
                <h className='text-white'>
                    <span className='text-secondary sm:text-blue-600 text-lg lg:text-base font-serif'>Сайновом Даниэлем</span>
                </h>
            </div>
        </div>
    )
}

export default Footer