import React from 'react'
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { HideLoading, ShowLoading } from "../../redux/rootSlice"
import axios from 'axios';
import {message} from "antd";

function AdminIntro() {
  const dispatch = useDispatch();
  const {portfolioData} = useSelector((state) => state.root);
  const onFinish = async(values) => {
    try {
      const tempCaption = values.caption.split("|");
      values.caption = tempCaption;
      dispatch(ShowLoading())
      const response = await axios.post("/api/portfolio/update-intro-ru", {
        ...values,
        _id: portfolioData.introrus._id
      });
      dispatch(HideLoading())
      if(response.data.success){
        message.success(response.data.message)
      }else{
        message.error(response.data.message)
      }
    } catch (error) {
      dispatch(HideLoading())
      message.error(error.message)
    }
  };

  return (
    <div>
      <Form onFinish={onFinish} layout='vertical' initialValues={{
        ...portfolioData.introrus,
        caption: portfolioData.introrus.caption.join(" | ")
      }}>
        <Form.Item name='welcomeText' label='Welcome text'>
          <input placeholder="Welcome text" />
        </Form.Item>
        <Form.Item name='firstName' label='First name'>
          <input placeholder="First name" />
        </Form.Item>
        <Form.Item name='lastName' label='Last name'>
          <input placeholder="Last name" />
        </Form.Item>
        <Form.Item name='caption' label='Captions'>
          <input placeholder="Captions" />
        </Form.Item>
        <Form.Item name='description' label='Description'>
          <textarea placeholder="Description" />
        </Form.Item>
        <div className='flex justify-end'>
          <button className='px-7 py-3 bg-primary text-white' type='submit'>
            Save
          </button>
        </div>
      </Form>
    </div>
  )
}

export default AdminIntro