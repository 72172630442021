import React from 'react'
import { Form, Modal, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { HideLoading, ReloadData, ShowLoading } from "../../redux/rootSlice"
import axios from 'axios';

function AdminProjects() {
    const dispatch = useDispatch()
    const { portfolioData } = useSelector((state) => state.root);
    const { projects } = portfolioData
    const [showAddEditModal, setShowAddEditModal] = React.useState(false)
    const [selectedItemForEdit, setSelectedItemForEdit] = React.useState(null)
    const [type, setType] = React.useState('add')

    const onFinish = async (values) => {
        try {
            const tempSkills = values.skills.split("|");
            values.skills = tempSkills;
            dispatch(ShowLoading())
            let response
            if (selectedItemForEdit) {
                response = await axios.post("/api/portfolio/update-project", {
                    ...values,
                    _id: selectedItemForEdit._id,
                });
            } else {
                response = await axios.post(
                    "/api/portfolio/add-project",
                    values
                );
            }

            dispatch(HideLoading())
            if (response.data.success) {
                message.success(response.data.message)
                setShowAddEditModal(false)
                setSelectedItemForEdit(null)
                dispatch(HideLoading())
                dispatch(ReloadData(true))
            } else {
                message.error(response.data.message)
            }
        } catch (error) {
            dispatch(HideLoading())
            message.error(error.message)
        }
    }

    const onDelete = async (item) => {
        try {
            dispatch(ShowLoading())
            const response = await axios.post("/api/portfolio/delete-project", {
                _id: item._id,
            });
            dispatch(HideLoading())
            if (response.data.success) {
                message.success(response.data.message)
                dispatch(HideLoading())
                dispatch(ReloadData(true))
            } else {
                message.error(response.data.message)
            }
        } catch (error) {
            dispatch(HideLoading())
            message.error(error.message)
        }
    }

    return (
        <div>
            <div className='flex justify-end mb-4'>
                <button className='bg-primary text-white px-5 py-2 rounded-md' onClick={() => {
                    setSelectedItemForEdit(null)
                    setShowAddEditModal(true)
                }}
                >Add Project
                </button>
            </div>
            <div className='grid grid-cols-4 gap-5 sm:grid-cols-1'>
                {projects.map((project) => (
                    <div className='flex flex-col shadow border p-5 border-gray-400 mb-5'>
                        <div>
                            <h1 className='text-secondary text-2xl mb-1 font-bold'>{project.title}</h1>
                            <hr className='mb-3' />
                            <img src={project.image} alt='' className='h-60 w-80 sm:h-40 sm:w-90 mb-2'/>
                            <h1 className='font-serif mb-1 text-xl'>{project.title2}</h1>
                            <h1>{project.description}</h1>
                        </div>
                        <div className='flex justify-end items-end mt-auto gap-2'>
                            <button className='bg-purple-600 text-white px-5 py-2 rounded-md'
                                onClick={() => {
                                    onDelete(project)
                                }}
                            >
                                Delete</button>
                            <button className='bg-primary text-white px-5 py-2 rounded-md'
                                onClick={() => {
                                    setSelectedItemForEdit(project)
                                    setShowAddEditModal(true)
                                    setType('edit')
                                }}
                            >Edit</button>
                        </div>
                    </div>
                ))}
            </div>

            {
                (type === "add" ||
                    selectedItemForEdit) && (<Modal
                        open={showAddEditModal}
                        title={selectedItemForEdit ? "Edit project" : "Add project"}
                        footer={null}
                        onCancel={() => {
                            setShowAddEditModal(false)
                            setSelectedItemForEdit(null)
                        }}
                    >
                        <Form
                            layout='vertical'
                            onFinish={onFinish}
                            initialValues={{
                                ...selectedItemForEdit,
                                skills : selectedItemForEdit?.skills.join("|")
                            }}
                        >
                            <Form.Item name='title' label='Title'>
                                <input placeholder="Title" />
                            </Form.Item>
                            <Form.Item name='image' label='Image'>
                                <input placeholder="Image" />
                            </Form.Item>
                            <Form.Item name='title2' label='Title2'>
                                <input placeholder="Title2" />
                            </Form.Item>
                            <Form.Item name='os' label='OS'>
                                <input placeholder="OS" />
                            </Form.Item>
                            <Form.Item name='description' label='Description'>
                                <textarea placeholder="Description" />
                            </Form.Item>
                            <Form.Item name='skills' label='Technologies'>
                                <input placeholder="Technologies" />
                            </Form.Item>
                            <Form.Item name='link' label='Link'>
                                <input placeholder="Link" />
                            </Form.Item>
                            <div className='flex justify-end'>
                                <button
                                    className='border-primary text-primary px-5 py-2 rounded-md'
                                    onClick={() => {
                                        setShowAddEditModal(false)
                                        setSelectedItemForEdit(null)
                                    }}>
                                    Cancel
                                </button>
                                <button className='bg-primary text-white px-5 py-2 rounded-md'>
                                    {selectedItemForEdit ? "Update" : "Add"}
                                </button>
                            </div>
                        </Form>
                    </Modal>
                )}
        </div>
    )
}

export default AdminProjects