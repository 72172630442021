import React from 'react'
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { HideLoading, ShowLoading } from "../../redux/rootSlice"
import axios from 'axios';
import {message} from "antd";

function AdminAbout() {
  const dispatch = useDispatch();
  const {portfolioData} = useSelector((state) => state.root);
  const onFinish = async(values) => {
    try {
      const tempSkills = values.skills.split("|");
      values.skills = tempSkills;
      dispatch(ShowLoading())
      const response = await axios.post("/api/portfolio/update-about-ru", {
        ...values,
        _id: portfolioData.aboutrus._id
      });
      dispatch(HideLoading())
      if(response.data.success){
        message.success(response.data.message)
      }else{
        message.error(response.data.message)
      }
    } catch (error) {
      dispatch(HideLoading())
      message.error(error.message)
    }
  };

  return (
    <div>
      <Form onFinish={onFinish} layout='vertical' initialValues={{
        ...portfolioData.aboutrus,
        skills: portfolioData.aboutrus.skills.join("|")
      }}>
        <Form.Item name='description1' label='Description1'>
          <textarea placeholder="Description1" />
        </Form.Item>
        <Form.Item name='description2' label='Description2'>
          <textarea placeholder="Description2" />
        </Form.Item>
        <Form.Item name='skills' label='Skills'>
          <textarea placeholder="Skills" />
        </Form.Item>
        <div className='flex justify-end'>
          <button className='px-7 py-3 bg-primary text-white' type='submit'>
            Save
          </button>
        </div>
      </Form>
    </div>
  )
}

export default AdminAbout