import React from 'react'

function LeftSider() {
    return (
        <div className='fixed left-0 bottom-0 px-12 sm:bg-inherit sm:static'>
            <div className='flex flex-col items-center'>
                <div className='flex flex-col gap-5 mb-5 sm:flex-row sm:mb-10'>
                    <a href='https://github.com/lightningcpu' target='_blank' rel='noopener noreferrer'>
                        <i class="ri-github-fill text-blue-600 opacity-90 text-2xl lg:text-xl sm:opacity-70 sm:text-3xl"></i> 
                    </a>
                    <a href='https://t.me/antice' target='_blank' rel='noopener noreferrer'>
                        <i class="ri-telegram-fill text-blue-600 opacity-90 text-2xl lg:text-xl sm:opacity-70 sm:text-3xl"></i>
                    </a>
                </div>
                <div className='w-[2px] h-32 bg-[#154148] sm:hidden'>

                </div>
            </div>
        </div>
    )
}

export default LeftSider