import React from 'react';
import Password from './Password'

function PasswordGen() {
  return (
    <div>
      <Password />
    </div>
  )
}

export default PasswordGen