import React, { useEffect } from 'react'
import { Tabs } from "antd";
import Header from '../../components/Header'
import { useSelector } from 'react-redux';
import AdminIntro from './AdminIntro';
import AdminAbout from './AdminAbout';
import Experiences from './AdminExperiences';
import AdminProjects from './AdminProjects';
import AdminCourses from './AdminCourses';
import AdminContact from './AdminContact';
import AdminIntroRu from './AdminIntroRu';
import AdminAboutRu from './AdminAboutRu';
import ExperiencesRu from './AdminExperiencesRu';
import AdminProjectsRu from './AdminProjectsRu';
import AdminCoursesRu from './AdminCoursesRu';
import AdminContactRu from './AdminContactRu';

const { TabPane } = Tabs;

const onChange = (key) => {
  console.log(key);
};

function AdminPage() {
  const { portfolioData } = useSelector((state) => state.root);

  useEffect(() => {
    if(!localStorage.getItem('token')){
      window.location.href = '/admin-login'
    }

  }, [])
  

  return (
    <div>
      <Header />
      <div className='flex justify-between'>
        <h1 className='text-3xl px-5 py-5 text-primary'>Portfolio customization</h1>
        <h1 className='underline text-primary px-5 rounded-md mt-7 mr-1 cursor-pointer' onClick={() => {
          localStorage.removeItem('token')
          window.location.href = "/"
        }}>Logout</h1>
      </div>
      {portfolioData &&      <div className='ml-5 mr-5'>
        <Tabs defaultActiveKey="1" onChange={onChange} tabPosition='left'>
            <TabPane tab="Intro" key="1">
              <AdminIntro />
            </TabPane>
            <TabPane tab="About" key="2">
              <AdminAbout />
            </TabPane>
            <TabPane tab="Experiences" key="3">
              <Experiences />
            </TabPane>
            <TabPane tab="Projects" key="4">
              <AdminProjects />
            </TabPane>
            <TabPane tab="Courses" key="5">
              <AdminCourses />
            </TabPane>
            <TabPane tab="Contact" key="6">
              <AdminContact />
            </TabPane>



            <TabPane tab="IntroRus" key="7">
              <AdminIntroRu />
            </TabPane>
            <TabPane tab="AboutRus" key="8">
              <AdminAboutRu />
            </TabPane>
            <TabPane tab="ExperiencesRus" key="9">
              <ExperiencesRu />
            </TabPane>
            <TabPane tab="ProjectsRus" key="10">
              <AdminProjectsRu />
            </TabPane>
            <TabPane tab="CoursesRus" key="11">
              <AdminCoursesRu />
            </TabPane>
            <TabPane tab="ContactRus" key="12">
              <AdminContactRu />
            </TabPane>
        </Tabs>
      </div>}
    </div>
  )
}

export default AdminPage;