import React from 'react'
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { HideLoading, ShowLoading } from "../../redux/rootSlice"
import axios from 'axios';
import { message } from "antd";

function AdminContact() {
    const dispatch = useDispatch();
    const { portfolioData } = useSelector((state) => state.root);
    const onFinish = async (values) => {
        try {
            dispatch(ShowLoading())
            const response = await axios.post("/api/portfolio/update-contact-ru", {
                ...values,
                _id: portfolioData.contactrus._id
            });
            dispatch(HideLoading())
            if (response.data.success) {
                message.success(response.data.message)
            } else {
                message.error(response.data.message)
            }
        } catch (error) {
            dispatch(HideLoading())
            message.error(error.message)
        }
    };

    return (
        <div>
            <Form onFinish={onFinish} layout='vertical' initialValues={{
                ...portfolioData.contactrus,
            }}>

                <Form.Item name='name' label='Name'>
                    <input placeholder="Name" />
                </Form.Item>
                <Form.Item name='age' label='Age'>
                    <input placeholder="Age" />
                </Form.Item>
                <Form.Item name='gender' label='Gender'>
                    <input placeholder="Gender" />
                </Form.Item>
                <Form.Item name='telegram' label='Telegram'>
                    <input placeholder="Telegram" />
                </Form.Item>
                <Form.Item name='email' label='Email'>
                    <input placeholder="Email" />
                </Form.Item>
                <Form.Item name='mobile' label='Mobile'>
                    <input placeholder="Mobile" />
                </Form.Item>
                <Form.Item name='country' label='Country'>
                    <input placeholder="Country" />
                </Form.Item>
                <div className='flex justify-end mb-5'>
                    <button className='px-7 py-3 bg-primary text-white' type='submit'>
                        Save
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default AdminContact