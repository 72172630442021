import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import { useSelector } from 'react-redux';

function AboutRu() {
    const { portfolioData } = useSelector((state) => state.root);
    const { aboutrus } = portfolioData;
    const { skills, description1, description2 } = aboutrus;


    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);


    return (
        <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
            <div className='sm:mt-10 rs:mt-[200px] rrs:mt-[250px]'>
                <SectionTitle title="Про меня" />
                <div className='flex flex-col md:gap-[350px] sm:gap-[670px] rrs:gap-[725px] si:gap-[875px]'>
                    <div className='flex py-5 lg:h-[65vh] la:h-[68vh] mdx:h-[50vh] de:h-[60vh] lg:py-0 md:h-[75vh] sm:h-[60vh]'>
                        <div className='flex items-center gap-[60px] lg:gap-0 de:flex-col'>
                            <div className='flex justify-start de:justify-center md:justify-start lg:w-1/2 sm:w-full md:w-full de:w-full'>
                                <img className='w-[500px] h-[325px] lg:w-[550px] lg:h-[335px] la:w-[490px] la:h-[315px] mdx:w-[450px] mdx:h-[295px] rrs:h-[275px] si:h-[225px]' src="/images/api.gif" alt="About Gif" />
                            </div>

                            <div className='w-2/3 flex flex-col flex-wrap de:flex-row sm:flex-col de:w-full gap-5 lg:w-1/2 md:w-full sm:mt-10 md:mt-[35px]'>
                                <p className='text-white text-xl lg:text-base font-serif sm:w-full'>{description1 || ''}</p>
                                <p className='text-white text-xl lg:text-base font-serif sm:w-full'>{description2 || ''}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col py-5 sm:mt-auto'>
                        <h1 className='text-tertiary text-2xl font-serif'>
                            Недавно использованные технологии:
                        </h1>
                        <div className='flex flex-wrap gap-8 mt-5'>
                            {skills.map((skill, index) => (
                                <div className='border border-tertiary py-3 px-[40px] lg:py-3 lg:px-10 rounded'>
                                    <h1 className='text-tertiary text-xl lg:text-base'>{skill}</h1>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutRu;