import React, { useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from '../../redux/rootSlice';

function Login() {
  const [user, setUser] = useState({
    username: '',
    password: '',
  });

  const dispatch = useDispatch();

  const login = async (e) => {
    e.preventDefault(); // Prevent form submission
    try {
      dispatch(ShowLoading());
      const response = await axios.post('/api/portfolio/admin-login', user);
      dispatch(HideLoading());

      if (response.data.success) {
        message.success(response.data.message);
        localStorage.setItem('token', JSON.stringify(response.data));
        window.location.href = '/admin';
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error.message);
      dispatch(HideLoading());
    }
  };

  return (
    <div className='flex justify-center items-center h-screen bg-primary'>
      <div className='flex flex-col w-96 gap-5 p-5 shadow border border-gray-500 bg-white'>
        <h1 className='text-2xl'>Login</h1>
        <hr />
        <form onSubmit={login} className='flex flex-col gap-5'>
          <input
            placeholder='Username'
            type='text'
            value={user.username}
            onChange={(e) => setUser({ ...user, username: e.target.value })}
          />
          <input
            placeholder='Password'
            type='password'
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />
          <button type='submit' className='bg-primary text-white p-2'>
            Sign in
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;