import React from 'react'

function Loader() {
  return (
    <div className='flex h-screen justify-center items-center fixed inset-0 bg-primary z-[100]'>
      <div className='flex flex-row gap-5 text-6xl font-semibold sm:text-3xl'>
        <h1 className='text-secondary d'>
          D
        </h1>
        <h1 className='text-tertiary s'>
          S
        </h1>
      </div>
    </div>
  )
}

export default Loader