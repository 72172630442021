import React, { useState } from 'react';

function Header() {
  const [language, setLanguage] = useState('en');

  const toggleLanguage = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    if (selectedLanguage === 'en') {
      // Redirect user to the root page
      window.location.href = '/';
    } else if (selectedLanguage === 'ru') {
      // Redirect user to the /ru directory
      window.location.href = '/ru';
    }
  };

  // Check if current URL is /admin
  const isNotAdminPage = window.location.pathname !== '/admin';

  return (
    <div className='sm:p-5 pt-7 pr-6 bg-primary flex justify-end'>
      {/* Render language switching only if not on /admin */}
      {isNotAdminPage && (
        <>
          <div className='flex border-r-0 border-2 border-tertiary rounded-l-md px-5 py-4 lg:px-3 lg:py-2 text-xl lg:text-base text-tertiary'>
            <button
              onClick={() => toggleLanguage('en')}
              className={language === 'en' ? 'selected-language' : ''}
            >
              en
            </button>
          </div>

          <div className='w-[1.9px] h-[65px] lg:h-[45px] bg-tertiary'>
          </div>

          <div className='flex border-l-0 border-2 border-tertiary rounded-r-md px-5 py-4 lg:px-3 lg:py-2 text-xl lg:text-base text-tertiary'>
            <button
              onClick={() => toggleLanguage('ru')}
              className={language === 'ru' ? 'selected-language' : ''}
            >
              ru
            </button>
          </div>
        </>
      )}
      {/* Language switching */}
    </div>
  );
}

export default Header;