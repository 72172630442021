import React from 'react'
import Header from '../../components/Header'
import IntroRu from './IntroRu'
import AboutRu from './AboutRu'
import ExperiencesRu from './ExperiencesRu'
import ProjectsRu from './ProjectsRu'
import CoursesRu from './CoursesRu'
import ContactRu from './ContactRu'
import Footer from './Footer'
import LeftSider from './LeftSider'
import { useSelector } from 'react-redux'

function HomeRu() {
  const { portfolioData } = useSelector(state => state.root);
  return (
    <div>
      <Header />
      {portfolioData && (    <div className='bg-primary px-40 sm:px-5'>
        <IntroRu />
        <AboutRu />
        <ExperiencesRu />
        <ProjectsRu />
        <CoursesRu />
        <ContactRu />
        <Footer />
        <LeftSider />
      </div>)}
    </div>
  )
}

export default HomeRu